<!-- 报名管理编辑 -->
<template>
  <div class="EditEnrollAdmin">
    <!-- 上面部分 -->
    <div class="top">
      <span style="font-size: 16px; font-weight: bold">报名管理编辑</span>
      <el-button class="el-btn" size="small" @click="goBack"> 返回 </el-button>
    </div>
    <!-- 表单 -->
    <div class="" style="display: flex">
      <el-form ref="form" :model="form" id="form-1" :rules="rules" class="" inline label-width="150px">
        <el-form-item label="登记单号">
          <el-input v-model="form.number" placeholder="请输入登记单号" disabled></el-input>
        </el-form-item>
        <el-form-item label="准考证">
          <el-input v-model="form.identity" placeholder="请输入准考证" disabled></el-input>
        </el-form-item>
        <el-form-item label="学员姓名" prop="studentName">
          <el-select v-model="form.studentName" filterable remote placeholder="请输入并选择学员姓名" :clearable="true" disabled
            :remote-method="students" @change="studentChange">
            <el-option v-for="item in form.studentOpt" :key="item.id" :label="item.username" :value="item.username">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="指导老师">
          <el-input v-model="form.instructor" placeholder="请输入指导老师" disabled></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="telephone">
          <el-input v-model="form.telephone" placeholder="请输入联系电话" disabled></el-input>
        </el-form-item>
        <el-form-item label="申请考试日期">
          <el-date-picker v-model="form.applytestDate" value-format="yyyy-MM-dd" type="date" placeholder="请选择考试日期"
            disabled>
          </el-date-picker>
        </el-form-item>
        <el-form-item label="下单人员" prop="nextPerson">
          <el-select v-model="form.nextPerson" filterable placeholder="请选择下单人员" :clearable="true"
            @change="nextPersonChange" disabled>
            <el-option v-for="item in form.nextPersonChoose" :key="item.id" :label="item.fullname"
              :value="item.fullname">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报考日期">
          <el-date-picker v-model="form.enrollDate" value-format="yyyy-MM-dd" type="date" placeholder="请选择报考日期"
            disabled>
          </el-date-picker>
        </el-form-item>
        <el-form-item label="申请大类">
          <el-select v-model="form.bigclass" placeholder="请选择申请大类" :clearable="true" disabled>
            <el-option v-for="item in form.bigclassChoose" :key="item.id" :label="item.exam_name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择科目">
          <el-select v-model="form.subject" placeholder="请选择科目" :clearable="true" @change="subjectChange" disabled>
            <el-option v-for="item in form.subjectChoose" :key="item.id" :label="item.course_name"
              :value="item.course_name">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="原等级值">
          <el-select v-model="form.originalGrade" placeholder="请选择原等级值" :clearable="true" disabled>
            <el-option v-for="item in form.gradeChoose" :key="item.id" :label="item.level" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报考等级">
          <el-select v-model="form.exam_level" placeholder="请选择" :clearable="true" @change="applyRankChange" disabled>
            <el-option v-for="item in form.exam_levelOpt" :key="item.id" :label="item.level" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考试日期">
          <el-date-picker v-model="form.testDate" value-format="yyyy-MM-dd" type="date" placeholder="请选择报考日期" disabled>
          </el-date-picker>
        </el-form-item>
        <el-form-item label="考试开始时间">
          <el-time-picker v-model="form.startTime" :picker-options="{
            selectableRange: '00:00:00 - 23:59:59',
          }" placeholder="请选择开始时间" disabled>
          </el-time-picker>
        </el-form-item>
        <el-form-item label="截止时间">
          <el-time-picker v-model="form.endTime" :picker-options="{
            selectableRange: '00:00:00 - 23:59:59',
          }" placeholder="请选择截止时间" disabled>
          </el-time-picker>
        </el-form-item>
        <el-form-item label="考试时长">
          <el-input v-model="form.duration" placeholder="请输入考试时长" disabled></el-input>
        </el-form-item>
        <el-form-item label="副证费">
          <el-input v-model="form.certificateFee" placeholder="请输入副证费" disabled></el-input>
        </el-form-item>
        <el-form-item label="考级报名费">
          <el-input v-model="form.registrationFee" placeholder="请输入考级报名费" disabled></el-input>
        </el-form-item>
        <el-form-item label="考级管理费">
          <el-input v-model="form.manageFee" placeholder="请输入考级管理费" disabled></el-input>
        </el-form-item>
        <el-form-item label="应收费金额">
          <el-input v-model="form.receivable" placeholder="请输入应收费金额" disabled></el-input>
        </el-form-item>
        <el-form-item label="实收金额">
          <el-input v-model="form.netReceipts" placeholder="请输入实收金额" disabled></el-input>
        </el-form-item>
        <el-form-item label="考试备注">
          <el-input v-model="form.remarks" type="textarea" class="text-ipt" placeholder="请输入考试备注">
          </el-input>
        </el-form-item>
        <el-form-item label="状态">
          <div style="width: 700px">
            <el-radio-group v-model="form.condition" disabled>
              <el-radio :label="1">草稿</el-radio>
              <el-radio :label="2">报考</el-radio>
              <el-radio :label="3">准考</el-radio>
              <el-radio :label="4">考试ok</el-radio>
              <el-radio :label="5">取消</el-radio>
              <el-radio :label="6">退款取消</el-radio>
              <el-radio :label="7">延期</el-radio>
              <el-radio :label="8">缺考</el-radio>
            </el-radio-group>
          </div>
        </el-form-item>
      </el-form>
      <!-- 照片 -->
      <div class="" style="margin: 10px 70px 0px 50px">
        <span style="color: #666; margin-bottom: 7px; display: inline-block">证件照片</span>
        <div class="" style="font-size: 12px; color: red; margin-bottom: 10px">
          *上传照片格式要求 413×551px（jpeg，jpg）
        </div>
        <el-upload class="upload-demo" drag :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"
          :show-file-list="false" action="https://artxt.szart.cn/api/public/index.php/api/common/upload">
          <img v-if="fullurl !== ''" :src="fullurl" class="avatar" />
          <i class="el-icon-upload" v-else></i>
          <div class="el-upload__text" v-if="fullurl == ''">
            将文件拖到此处，或
            <span style="color: red"> 点击上传 </span>
          </div>
          <div class="el-upload__tip" v-if="fullurl == ''">
            只能上传jpg/jpeg文件，且不超过1M
          </div>
        </el-upload>
        <div class="" style="font-size: 14px; color: red">
          点击或者拖拽上传证件照片
        </div>

        <!-- 作品 -->
        <div style="margin-top: 50px">
          <span style="color: #666; margin-bottom: 7px; display: inline-block">作品照片</span>
          <div style="font-size: 12px; color: red; margin-bottom: 10px">
            *上传照片格式要求 大于3k小于3M（jpeg，jpg）
          </div>
          <el-upload class="upload-demo" drag :on-success="workshandleAvatarSuccess"
            :before-upload="worksbeforeAvatarUpload" :show-file-list="false"
            action="https://artxt.szart.cn/api/public/index.php/api/common/upload">
            <img v-if="worksfullurl !== ''" :src="worksfullurl" class="avatar" />
            <i class="el-icon-upload" v-else></i>
            <div class="el-upload__text" v-if="worksfullurl == ''">
              将文件拖到此处，或
              <span style="color: red"> 点击上传 </span>
            </div>
            <div class="el-upload__tip" v-if="worksfullurl == ''">
              只能上传jpg/jpeg文件，且不小于3k不超过3M
            </div>
          </el-upload>
          <div style="font-size: 14px; color: red">
            点击或者拖拽上传作品照片
          </div>
        </div>
      </div>
    </div>

    <el-divider></el-divider>
    <!-- 底部 提交与取消 -->
    <div slot="footer" class="dialog-footer">
      <el-button style="background-color: #18bc9c; color: #ffffff" @click="submitForm('form')">提 交</el-button>
      <el-button class="btn" @click="resetForm('form')">取 消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        number: "", //登记单号
        name: "", //机构名称
        nameChoose: [], //机构名称选项
        identity: "", //准考证
        studentName: "", //学员姓名
        studentOpt: [], //学员选项
        student_id: 0, //学员id
        instructor: "", //指导老师
        telephone: "", //联系电话
        applytestDate: "", //申请考试日期
        nextPerson: "", //下单人员
        nextPerson_id: 0, //下单人员id
        nextPersonChoose: [], //下单人员选项
        enrollDate: "", //报考日期
        bigclass: "", //申请大类
        bigclass_id: 0, //申请大类id
        bigclassChoose: [], //申请大类选项
        subject: "", //选择科目
        subject_id: 0, //科目id
        subjectChoose: [], //科目选项
        originalGrade: "", //原等级值
        gradeChoose: [], //原等级值选择
        exam_level: "", //报考等级
        exam_levelOpt: [], //报考等级选项
        testDate: "", //考试日期
        startTime: "", //考试开始时间
        endTime: "", //截止时间
        duration: "", //考试时长
        certificateFee: "", //副证费
        registrationFee: "", //考级报名费
        manageFee: "", //考级管理费
        receivable: "", //应收费金额
        netReceipts: "", //实收金额
        fraction: "", //考试分数
        epilog: "", //考试结论
        grade: "", //选择等级
        remarks: "", //考试备注
        condition: 1, //状态
        idcard: "", //身份证
      },
      rules: {
        studentName: [
          {
            required: true,
            message: "学员姓名不能为空",
            trigger: "blur",
          },
        ],
        telephone: [
          {
            required: true,
            message: "联系电话不能为空",
            trigger: "blur",
          },
        ],
        applytestDate: [
          {
            required: true,
            message: "申请考试日期不能为空",
            trigger: "change",
          },
        ],
        enrollDate: [
          {
            required: true,
            message: "报考日期不能为空",
            trigger: "change",
          },
        ],
      },
      bool: false,
      Visible: false,
      ruleform: {
        search: "", //关键字
      },
      currentPage: 1,
      currentLimit: 10, //条数
      total: 0,
      imageUrl: null,
      bool: false,
      fullurl: "", //显示图片
      url: "", //传的图片
      worksfullurl: "", //显示图片
      worksurl: "", //传的图片
    };
  },
  created() {
    // 初次渲染
    this.$request({
      url: "/api/mechanismexam/signupDetail",
      method: "POST",
      data: {
        signup_id: this.$route.query.id,
        uid: localStorage.getItem("token"),
      },
    }).then((res) => {
      if (res.code == 1) {
        let data = res.data;
        let form = this.form;
        form.number = data.enroll_number; //登记单号
        form.identity = data.certificate_no; //准考证
        form.studentName = data.name; //学员姓名
        form.instructor = data.teacher; //指导老师
        form.telephone = data.phone; //联系电话
        form.applytestDate = data.apply_date; //申请考试日期
        form.nextPerson_id = data.order_uid; //下单人员id
        form.nextPerson = data.order_name; //下单人员
        form.enrollDate = data.exam_date; //报考日期
        form.bigclass = parseInt(data.apply_category); //考试大类名称id
        form.subject_id = data.course_id; //学科id
        form.subject = data.course; //学科
        form.originalGrade = data.original_level; //原等级
        form.exam_level = data.exam_level; //报考等级
        form.testDate = data.exam2_date; //考试日期
        form.startTime = data.exam_startdate; //考试开始时间
        form.endTime = data.exam_enddate; //截止时间
        form.duration = data.exam_duration; //考试时长
        form.certificateFee = data.certificatecopy_price; //副证费
        form.registrationFee = data.enroll_price; //考级报名费
        form.manageFee = data.manage_price; //考级管理费
        form.receivable = data.price1; //应收费金额
        form.netReceipts = data.price2; //实收金额
        form.remarks = data.remark; //考试备注
        form.condition = data.status; //状态
        form.name = data.org_name; //机构名称
        this.fullurl = data.head_image; //头像
        if (data.photo_url) {
          this.worksfullurl = data.photo_url.includes('https://artxt.szart.cn/api/public') ? data.photo_url : "https://artxt.szart.cn/api/public" + data.photo_url; // 作品
          // this.worksfullurl =
          //   "https://artxt.szart.cn/api/public" + data.photo_url; // 作品
        }
      }
    });
    // 选择机构
    this.$request({
      url: "/api/mechanism/list",
      method: "POST",
      data: {
        page: 1,
        limit: 1000,
      },
    }).then((res) => {
      this.form.nameChoose = res.data.list;
    });

    // 申请大类
    this.$request({
      url: "/api/ExamCategory/list",
      method: "POST",
      data: {
        page: 1,
        limit: 100,
      },
    }).then((res) => {
      if (res.code == 1) {
        this.form.bigclassChoose = res.data.list;
      }
    });

    //科目数据
    this.$request({
      url: "/api/course/list",
      method: "POST",
      data: {
        page: 1,
        limit: 100,
      },
    }).then((res) => {
      if (res.code == 1) {
        this.form.subjectChoose = res.data.list;
      }
    });

    //原等级值数据
    this.$request({
      url: "/api/level/list",
      method: "POST",
      data: {
        page: 1,
        limit: 100,
      },
    }).then((res) => {
      if (res.code == 1) {
        this.form.gradeChoose = res.data.list;
        this.form.exam_levelOpt = res.data.list;
      }
    });

    // 下单人数据
    this.$request({
      url: "/api/humanresources/list",
      method: "POST",
      data: {
        page: 1,
        limit: 100,
      },
    }).then((res) => {
      if (res.code == 1) {
        this.form.nextPersonChoose = res.data.list;
      }
    });
  },
  methods: {
    handleSizeChange(val) {
      this.currentLimit = val;
      this.getStudent();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getStudent();
    },
    goBack() {
      //返回
      this.$router.go(-1);
    },

    //报考等级
    applyRankChange(e) {
      this.form.registrationFee = this.form.exam_levelOpt.find(
        (item) => item.id == e
      ).price;
    },

    submitForm(form) {
      //提交
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.bool) {
            return;
          }
          this.bool = true;
          let form = this.form;
          this.$request({
            url: "/api/mechanismexam/signupEdit",
            method: "POST",
            data: {
              signup_id: this.$route.query.id,
              uid: localStorage.getItem("token"),
              phone: form.telephone, //联系电话
              teacher: form.instructor, //授课老师
              course_id: form.subject_id, //学科ID
              original_level: form.originalGrade, //原等级
              exam_level: form.exam_level, //报考等级
              order_name: form.nextPerson, //下单人名字
              order_uid: form.nextPerson_id, //下单人ID
              apply_category: form.bigclass, //考试大类ID
              remark: form.remarks, //备注
              apply_date: form.applytestDate, //申请考试日期
              exam_date: form.enrollDate, //报考日期
              name: form.name, //机构名称
              head_image: this.url ? this.url : this.fullurl.split("public")[1], //头像
              photo_url: this.worksurl, // 作品
            },
          })
            .then((res) => {
              this.$message({
                message: "编辑列表成功",
                type: "success",
              });
              setTimeout(() => {
                this.goBack();
              }, 1500);
              //   if (res.code == 1) {
              //     this.$message({
              //       message: "编辑列表成功",
              //       type: "success",
              //     });
              //     setTimeout(() => {
              //       this.goBack();
              //     }, 1500);
              //   } else {
              //     this.$message({
              //       message: res.msg,
              //       type: "error",
              //     });
              //     setTimeout(() => {
              //       this.goBack();
              //     }, 1500);
              //   }
            })
            .catch(() => {
              this.bool = false;
            });
        } else {
          return false;
        }
      });
    },

    resetForm(form) {
      //取消
      this.$router.go(-1);
      this.$refs[form].resetFields();
    },

    bigclassChange(e) {
      //考试大类
      let result = this.form.bigclassChoose.find((item) => item.exam_name == e);
      this.form.bigclass_id = result.id;
    },

    subjectChange(e) {
      //选择科目
      let result = this.form.subjectChoose.find(
        (item) => item.course_name == e
      );
      this.form.subject_id = result.id;
    },

    nextPersonChange(e) {
      //下单人
      let result = this.form.nextPersonChoose.find(
        (item) => item.fullname == e
      );
      this.form.nextPerson_id = result.id;
    },

    studentName() {
      //学员弹窗
      this.Visible = true;
      this.getStudent();
    },

    //学生数据
    students(e) {
      this.$request({
        url: "/api/student/list",
        method: "POST",
        data: {
          page: 1,
          limit: 100,
          key: e,
        },
      }).then((res) => {
        this.form.studentOpt = res.data.list;
      });
    },

    studentChange(e) {
      //学生数据
      let result = this.form.studentOpt.find((item) => item.username == e);
      this.form.student_id = result.id;
      this.form.studentName = result.username;
      this.form.idcard = result.idcard;
    },

    query() {
      //查询
      this.currentPage = 1;
      this.getStudent();
    },

    Reset() {
      //重置
      this.ruleform.search = ""; //关键字
      this.getStudent();
    },

    // 上传图片
    handleAvatarSuccess(res, file) {
      this.imageUrl = res.data;
      this.fullurl = this.imageUrl.fullurl;
      // this.url = this.imageUrl.fullurl;
      this.url = this.imageUrl.url;
      this.$message.success("上传成功");
    },

    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpg";
      const isJPEG = file.type === "image/jpeg";
      // const isPNG = file.type === 'image/png';
      const isLt1M = file.size / 1024 / 1024 < 1;
      let flag = [isJPG, isJPEG].includes(true);
      if (!flag) {
        this.$message.error("上传头像图片只能是 JPG/JPEG 格式!");
      }
      if (!isLt1M) {
        this.$message.error("上传头像图片大小不能超过 1MB!");
      }
      return flag && isLt1M;
    },

    // 上传作品
    workshandleAvatarSuccess(res, file) {
      this.worksfullurl = res.data.fullurl;
      // this.worksurl = res.data.fullurl;
      this.worksurl = res.data.url;
      this.$message.success("上传成功");
    },

    worksbeforeAvatarUpload(file) {
      const wisJPG = file.type === "image/jpg";
      const wisJPEG = file.type === "image/jpeg";
      // const isPNG = file.type === 'image/png';
      const wisLt1M = file.size / 1024 / 1024 < 3;
      const wisLt3K = file.size / 1024 > 3;
      let wflag = [wisJPG, wisJPEG].includes(true);
      if (!wflag) {
        this.$message.error("上传作品图片只能是 JPG/JPEG 格式!");
      }
      if (!wisLt3K) {
        this.$message.error("上传作品图片大小不小于 3K!");
      }
      if (!wisLt1M) {
        this.$message.error("上传作品图片大小不能超过 3MB!");
      }
      return wflag && wisLt1M && wisLt3K;
    },
  },
};
</script>

<style scoped="scoped">
.EditEnrollAdmin {
  width: 100%;
  height: 100%;
}

/* 上面部分 */
.top {
  padding-top: 25px;
  margin: 0px 24px 39px 28px;
  display: flex;
  justify-content: space-between;
}

.el-btn {
  background: #18bc9c;
  padding-left: 25px;
  background-position: 5px 7px;
  display: inline-block;
  background-image: url(../../assets/back.png);
  background-repeat: no-repeat;
  color: #ffffff;
}

/* 表单部分 */
.el-form {
  display: flex;
  flex: 1;
  justify-content: start;
  flex-wrap: wrap;
}

::v-deep .el-form-item__label {
  color: #000000;
}

.el-input {
  width: 300px;
  height: 37px !important;
}

.el-select {
  width: 300px;
}

::v-deep .el-input.is-disabled .el-input__inner {
  background-color: #fff;
  color: #333333;
}

/* 单选按钮 */
#form-1 ::v-deep .el-radio__input.is-checked .el-radio__inner {
  background-color: #18bc9c;
}

#form-1 ::v-deep.el-radio__input.is-checked+.el-radio__label {
  color: #18bc9c;
}

#form-1 ::v-deep .el-radio__inner:hover {
  border-color: #18bc9c;
}

/* 底部提交 取消 */
.dialog-footer {
  text-align: center;
  padding-bottom: 20px;
}

.btn:hover {
  color: #666;
  border-color: #ccc;
  background-color: #ffffff;
}

.el-table {
  margin: 20px;
}

.ruleform ::v-deep .el-input {
  width: 200px !important;
  height: 33px;
  margin-left: 20px;
}

.ruleform ::v-deep .el-input__inner {
  width: 200px !important;
  height: 33px;
  margin-left: 20px;
}

::v-deep .el-dialog__body {
  padding: 0px !important;
}

::v-deep .el-dialog__title {
  font-weight: bold;
}

::v-deep .el-table__cell {
  color: #000;
  padding: 0px;
  height: 35px;
}

::v-deep .dig .el-dialog {
  width: 900px !important;
  height: 600px !important;
  display: flex !important;
  flex-direction: column !important;
}

/* 上传图片 */
::v-deep .el-upload-dragger {
  width: 173px;
  height: 211px;
  position: relative;
}

.el-upload-dragger .el-icon-upload {
  margin-top: 25px;
}

::v-deep .el-upload__text,
.el-upload__tip {
  font-size: 12px;
  width: 108px;
  margin: 8px auto;
}

.avatar {
  width: 173px;
  height: 211px;
}

/* 分页 */
.el-pagination {
  margin: 20px;
  margin-top: 0px;
}

::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #2c3e50 !important;
}
</style>
